import React from 'react';
import Navigation from '../components/navigation';
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from '../components/footer';
import "../scss/about.scss"

import profileImg from '../images/profile.jpg';

const about = () => (
  <Layout>
    <SEO title="About" keywords={[`photographer`, `fotograf`, `dusseldorf`, `düsseldorf`, `germany`, `写真家`, `portrait`, `porträt`, `カメラマン`, `TFP`, `landscape`, `hobby`, `japanese`, `street`, `outdoor`]} />
    <Navigation about={true} />

    <div className="profile-wrapper">
      <h2>About Me</h2>
      <div className="myProfile">

        <div className="myProfile__img">
          <img src={profileImg} />
        </div>

        <div className="myProfile__description">
          <h2>Takuro Yakubo</h2>
          <p>
            My name is Takuro Yakubo, I'm a part time photographer and full time web developer currently living in Düsseldorf, Germany.
            <br />
            I love traveling and taking pictures of landscapes and streets. 
            <br />
            Right now, I'm starting to get into portrait photography world and currently looking for models for the portrait project.
            <br />
            If you are interested, don't hesitate to contact me from <span><Link to='/contact'>here</Link></span>. 
            <br />
            <br />
            Thank you for visiting my portfolio website!
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
);

export default about;
